<template>
  <div class="participant-block">
    <menu-breadcrumb>
      <el-breadcrumb-item>會員管理</el-breadcrumb-item>
    </menu-breadcrumb>

    <div class="container">
      <table-title>
        <p>
          會員列表 <span class="unit">數量：{{ total }}</span>
        </p>
      </table-title>

      <el-table :data="participant_data">
        <el-table-column
          prop="participant_id"
          label="ID"
          fixed="left"
          width="120"
          sortable
        ></el-table-column>
        <el-table-column
          prop="username"
          label="信箱"
          sortable
        ></el-table-column>
        <el-table-column prop="name" label="姓名"></el-table-column>
        <el-table-column
          prop="login_type"
          label="登入方式"
          width="120"
        ></el-table-column>
        <el-table-column
          prop="created_at"
          label="創建時間"
          width="200"
          sortable=""
        >
          <template v-slot="{ row }">
            {{ $formatTime(row["created_at"]) }}
          </template>
        </el-table-column>
        <el-table-column
          prop="updated_at"
          label="更新時間"
          width="200"
          sortable
        >
          <template v-slot="{ row }">
            {{ $formatTime(row["updated_at"]) }}
          </template>
        </el-table-column>
        <el-table-column label="編輯" fixed="right" width="200">
          <template v-slot:header="{}">
            <el-input
              v-model="setup.search"
              size="mini"
              placeholder="請輸入關鍵字"
              @keyup.enter.native="handleSearch"
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="handleSearch"
              ></el-button>
            </el-input>
          </template>

          <template v-slot="{ row }">
            <el-button
              icon="el-icon-setting"
              size="mini"
              type="primary"
              @click="handleRouter('DetailParticipant', row.participant_id)"
              >內容</el-button
            >
            <el-button
              icon="el-icon-edit"
              size="mini"
              type="warning"
              @click="handleRouter('EditParticipant', row.participant_id)"
              >編輯</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <table-pagination
        :total="total"
        :limit="setup.limit"
        :page="setup.page"
        @change-page="handleChangePage"
      ></table-pagination>
    </div>
  </div>
</template>

<script>
import { getParticipantsPagination } from "@/api/participant";

export default {
  name: "Participant",
  data() {
    return {
      participant_data: [],
      total: 0,
      setup: {
        page: 0,
        limit: 25,
        search: "",
      },
    };
  },
  created() {
    this.handleGetParticipants();
  },
  methods: {
    async handleGetParticipants() {
      const { results, total } = await getParticipantsPagination(this.setup);
      this.participant_data = results;
      this.total = total;
    },
    async handleChangePage(page) {
      this.setup.page = page;

      await this.handleGetParticipants();
    },
    async handleSearch() {
      this.setup.page = 0;

      let query = {
        p: this.setup.page,
        s: encodeURIComponent(this.setup.search),
      };

      if (decodeURIComponent(query.s).length === 0) delete query.s;

      await this.$router.push({ query });

      await this.handleGetParticipants();
    },
    handleRouter(name, id) {
      this.$router.push({ name, params: { id } });
    },
  },
};
</script>

<style lang="scss" scoped></style>
