import request from "@/utils/request";

/**
 * Get participant.
 *
 * @param {number} id
 */
export function getParticipant(id) {
  return request({
    url: `api/v1/participants/${id}`,
    method: "GET",
  });
}

/**
 * Get participants with pagination.
 *
 * @param {object} [params]
 * @param {number} [params.limit]
 * @param {number} [params.page]
 * @param {number} [params.search] - search email and name
 */
export function getParticipantsPagination(params) {
  return request({
    url: "api/v1/participants/page",
    method: "GET",
    params,
  });
}

/**
 * Update the participant.
 *
 * @param {number} participant_id
 * @param {object} data
 * @param {string} data.username
 * @param {string} data.name
 * @param {string} data.address
 * @param {'other' | 'male' | 'female'} data.gender
 * @param {string} data.job
 * @param {string} data.login_type
 * @param {string} data.note
 */
export function updateParticipant(participant_id, data) {
  return request({
    url: `api/v1/participants/${participant_id}`,
    method: "PATCH",
    data,
  });
}

/**
 * Get participant records according participant id with pagination and search features.
 *
 * @param {number} participant_id
 * @param {object} [params]
 * @param {number} [params.limit]
 * @param {number} [params.page]
 * @param {string} [params.search]
 */
export function getParticipantRecordsPagination(participant_id, params) {
  return request({
    url: `api/v1/participants/records/page/${participant_id}`,
    method: "GET",
    params,
  });
}
